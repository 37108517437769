import React, { useEffect } from 'react';
import * as Ink from '@carta/ink';

import SampleViewer from '../../Page/SampleViewer';

const EmptyState = props => (
  <Ink.EmptyState type="page" text="No examples available" icon="notfound">
    <Ink.Anchor type="btn" href="javascript:void(0)">
      Add an example
    </Ink.Anchor>
  </Ink.EmptyState>
);

const SamplesTab = props => {
  useEffect(() => {
    if (window.location.hash !== '') {
      setTimeout(() => {
        document.getElementById(window.location.hash.slice(1)).scrollIntoView();
      }, 500);
    }
  });

  return (
    <Ink.ContextProvider>
      <Ink.VStack spacing="xlarge">
        {props.component.samples && props.component.samples.length > 0 ? (
          props.component.samples
            .filter(sample => sample.environments.includes('docs'))
            .map(sample => <SampleViewer key={sample.name} sample={sample} />)
        ) : (
          <EmptyState />
        )}
      </Ink.VStack>
    </Ink.ContextProvider>
  );
};

export default SamplesTab;
