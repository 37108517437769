import React from 'react';
import * as Ink from '@carta/ink';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { MDXRendererWithMapping, MDRendererWithMapping } from '../../Common/MarkdownRenderers';
import PreviewCard from '../../Common/PreviewCard';

const UsageTab = props => {
  const noUsageGuides =
    props.usageGuide === null &&
    (props.component.compiledMd === '' || props.component.compiledMd === null) &&
    props.relatedPatterns.nodes.length === 0 &&
    props.relatedHooks.nodes.length === 0;

  const componentGuide = props.component.compiledMd;

  const inlinePatternGuide = props.usageGuide;

  const hasRelatedPatterns = props.relatedPatterns.nodes && props.relatedPatterns.nodes.length > 0;
  const hasRelatedComponentGuides = props.relatedComponentGuides.nodes.length > 0 && props.relatedComponentGuides.nodes;
  const hasRelatedHooks = props.relatedHooks.nodes && props.relatedHooks.nodes.length > 0;
  const relatedContentCards = hasRelatedPatterns || hasRelatedHooks || hasRelatedComponentGuides;

  React.useEffect(() => {
    if (typeof window !== 'undefined' && window.location.hash.split('#').length > 2) {
      const hash = window.location.hash.split('#')[2];
      scrollTo(`#${hash}`);
    }
  });

  return (
    <>
      {noUsageGuides && (
        <Ink.EmptyState text="No usage pattern available" type="page" icon="notfound">
          <Ink.Anchor
            target="_blank"
            preset="new-tab"
            type="btn"
            href="https://ink.carta.com/contributing/adding-guides"
          >
            Write a pattern
          </Ink.Anchor>
        </Ink.EmptyState>
      )}

      {componentGuide && <MDRendererWithMapping md={props.component.compiledMd} isPlainText />}

      {inlinePatternGuide && <MDXRendererWithMapping md={props.usageGuide.body} />}

      {relatedContentCards && (
        <Ink.Box top="xlarge" bottom="xlarge">
          <Ink.Heading2>Related content</Ink.Heading2>
          <Ink.Tiles columns={[1, 2, 2, 3, 4]}>
            {props.relatedPatterns.nodes.map(pattern => (
              <PreviewCard
                key={pattern.frontmatter.title}
                title={pattern.frontmatter.title}
                text={pattern.frontmatter.subtitle}
                actionLabel="View pattern"
                actionHref={`/patterns/${pattern.slug}`}
                previewWithCheckerboard
              />
            ))}
            {props.relatedComponentGuides.nodes.map(guide => (
              <PreviewCard
                key={guide.frontmatter.title}
                title={guide.frontmatter.title}
                text={guide.frontmatter.subtitle}
                actionLabel="View component guide"
                actionHref={`/components/${guide.frontmatter.componentUsageGuide[0]}/usage`}
                previewWithCheckerboard
              />
            ))}
            {props.relatedHooks.nodes.map(hook => (
              <PreviewCard
                key={hook.frontmatter.title}
                title={hook.frontmatter.title}
                text={hook.frontmatter.subtitle}
                actionLabel="View hook"
                actionHref={`/hooks/${hook.slug}`}
                previewWithCheckerboard
              />
            ))}
          </Ink.Tiles>
        </Ink.Box>
      )}
    </>
  );
};

export default UsageTab;
