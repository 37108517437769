import React from 'react';
import * as Ink from '@carta/ink';
import humanize from 'humanize-react';
import { useAnalyticsV2 } from '@carta/fep-analytics';

const htmlMap = {
  HTMLAnchorElement: ['a'],
  HTMLButtonElement: ['button'],
  HTMLDivElement: ['div'],
  HTMLFieldSetElement: ['fieldset'],
  HTMLInputElement: ['input'],
  HTMLLabelElement: ['label'],
  HTMLLegendElement: ['legend'],
  HTMLOListElement: ['ol'],
  HTMLLIElement: ['li'],
  HTMLParagraphElement: ['p'],
  HTMLSelectElement: ['select'],
  HTMLSpanElement: ['span'],
  HTMLTableBody: ['tbody'],
  HTMLTableCellElement: ['td'],
  HTMLTableElement: ['table'],
  HTMLTableHead: ['thead'],
  HTMLTableHeaderCellElement: ['th'],
  HTMLTableRowElement: ['tr'],
  HTMLTableSectionElement: ['tfoot'],
  HTMLTextAreaElement: ['textarea'],
  HTMLUListElement: ['ul'],
  LayoutHTMLType: ['div', 'ol', 'ul', 'span'],
} as const;

export const MdnExternalLinks = ({ type }: { type: keyof typeof htmlMap }) => {
  const { trackAction } = useAnalyticsV2();

  const links = htmlMap[type].map(item => (
    <Ink.Anchor
      href={`https://developer.mozilla.org/en-US/docs/Web/HTML/Element/${item}`}
      title={`See more attributes on '${item}' at MDN`}
      target="_blank"
      preset="new-tab"
      onClick={() => {
        trackAction({
          elementId: `mdn-link-${item}`,
          type: 'click',
        });
      }}
    >
      {`<${item}>`}
    </Ink.Anchor>
  ));

  return humanize(links, { oxfordComma: true, conjunction: 'or' });
};
