import React from 'react';
import * as Ink from '@carta/ink';
import { Link } from 'gatsby';
import capitalize from 'lodash/capitalize';
import { useAnalyticsV2 } from '@carta/fep-analytics';
import { makeSlug } from '../../helpers';

type Props = {
  activeNavItem: string;
  displayName: string;
  items: string[];
};

const ComponentNavigation = (props: Props) => {
  const { trackAction } = useAnalyticsV2();
  const componentNavName = props.displayName;
  return (
    <Ink.Box bottom="gutter">
      <Ink.HorizontalNav>
        {props.items.map(item => (
          <Ink.HorizontalNav.Item
            active={props.activeNavItem === item}
            render={() => (
              <Link
                onClick={() =>
                  trackAction({
                    type: 'click',
                    elementId: `${makeSlug(componentNavName)}-tab-${item}`,
                  })
                }
                to={`/components/${componentNavName}/${makeSlug(item)}`}
              >
                {capitalize(item.replace('-', ' '))}
              </Link>
            )}
            key={item}
          />
        ))}
      </Ink.HorizontalNav>
    </Ink.Box>
  );
};

export default ComponentNavigation;
