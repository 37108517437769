import React from 'react';
import * as Ink from '@carta/ink';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { useAnalyticsV2 } from '@carta/fep-analytics';
import { MDRendererWithMapping } from '../../Common/MarkdownRenderers';

const AccessibilityTab = props => {
  const { trackAction } = useAnalyticsV2();
  const { accessibilityMd } = props.component;

  const noAccessibilityGuide = !accessibilityMd;

  React.useEffect(() => {
    if (typeof window !== 'undefined' && window.location.hash.split('#').length > 2) {
      const hash = window.location.hash.split('#')[2];
      scrollTo(`#${hash}`);
    }
  });

  return (
    <>
      {noAccessibilityGuide && (
        <Ink.EmptyState text="No accessibility information available" type="page" icon="notfound">
          <Ink.Anchor
            target="_blank"
            preset="new-tab"
            type="btn"
            href="https://ink.carta.com/contributing/adding-guides"
            onClick={() =>
              trackAction({
                elementId: 'add-accessibility-information',
                type: 'click',
              })
            }
          >
            Help us add accessibility information
          </Ink.Anchor>
        </Ink.EmptyState>
      )}

      {accessibilityMd && <MDRendererWithMapping md={accessibilityMd} isPlainText />}
    </>
  );
};

export default AccessibilityTab;
